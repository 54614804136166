import { Link } from "react-router-dom"
import Career from "./career.json"
const styles = {
    section: {
      fontSize: "18px",
      color: "#292b2c",
      backgroundColor: "#fff",
      padding: "0 20px"
    },
    wrapper: {
      textAlign: "center",
      margin: "0 auto",
      marginTop: "50px"
    },
    img: {
        float: "left",
        margin: "15px",
        maxWidth: "40vw"
        
      },
    
      p: {
        textAlign: "justify",
      },
      table: {
        borderCollapse: "collapse",
        width: "100%",
        
        margin: "30px auto",
        color: "#6F8BA4",
      },
      linkColor: {
        color: "#6F8BA4",
      }
      
  }
  var ulStyle = {
    marginTop:'0px',
    paddingTop:'10px',
  }
export default function Careers() {
  return(
      <div>
        <section className="page-title bg-1">
          <div className="">
            <div className="row">
              <div className="col-md-12">
                <div className="block">
                  {/* <span className="text-white">Careers</span> */}
                  <h1 className="text-capitalize mt-5 text-lg">Careers</h1>
                  <ul className="list-inline breadcumb-nav" style={ulStyle}>
                    <li className="list-inline-item"><a href="/" className="text-white">Home</a></li>
                    <li className="list-inline-item"><span className="text-white">/</span></li>
                    <li className="list-inline-item"><a href="#" className="text-white-50">Careers</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section awards bg-light">
            <div className="container">
                <h4 className="mb-4">Careers at Apricot Info Tech, an IT Consultancy Company.!</h4>
                {/* <div className="row">
                    <div className="col-lg-12">
                        <div className="why-content">
                            <p style={styles.p}>At Apricot Info Tech, we are dedicated to providing exceptional services to our clients by leveraging the latest technologies and industry best practices. Our team of IT professionals is passionate about helping our clients achieve their business objectives and stay ahead of the competition.</p>

                            <p style={styles.p}>We are always on the lookout for talented individuals who share our passion for technology and our commitment to excellence. If you are an experienced IT professional looking for a challenging and rewarding career, we would love to hear from you.</p>

                            <p style={styles.p}>At Apricot Info Tech, we believe in investing in our employees and providing them with the tools and resources they need to succeed. We offer a dynamic and supportive work environment that fosters collaboration, innovation, and growth.</p>

                            <p style={styles.p}>We value diversity and inclusion and are committed to creating a workplace that is welcoming and respectful to all. We offer competitive salaries and benefits packages, as well as opportunities for professional development and career advancement.</p>

                            <p style={styles.p}>If you are interested in joining our team, please check our job listings page for current openings. If there are no current openings that match your skills and experience, you may still submit your resume and cover letter for future consideration.</p>
                        </div>
                    </div>
                </div> */}
            </div>
        </section>
        {/* contact form start */}
        <section className="section case-study">
          <div className="container">
              <h4 className="mb-4">Current Openings</h4>
              <table className="table table-hover table-borderless" style={styles.table}>
                <tr>
                  <th>Position ID</th>
                  <th>Title</th>
                  <th>Category</th>
                </tr>
                <tbody>
                  {Career.map((Careers, index) => (  
                    <tr data-index={index}>
                      <td style={styles.linkColor}><a href={Careers.link}>{Careers.postionId}</a></td>  
                      <td><a href={Careers.link}>{Careers.title}</a></td>
                      <td><a href={Careers.link}>{Careers.category}</a></td>   
                    </tr>  
                  ))}
                </tbody> 
              </table>
          </div>
        </section>
      </div>
    );
  }