import { useParams, Link } from "react-router-dom"
import Careers from "./career.json"
const styles = {
    section: {
      fontSize: "18px",
      color: "#292b2c",
      backgroundColor: "#fff",
      padding: "0 20px"
    },
    wrapper: {
      textAlign: "center",
      margin: "0 auto",
      marginTop: "50px"
    },
    img: {
        float: "left",
        margin: "15px",
        maxWidth: "40vw"
        
      },
    
      p: {
        textAlign: "justify",
      }
  }
  var ulStyle = {
    marginTop:'0px',
    paddingTop:'10px',
  }
export default function Career() {
    const params = useParams();
    const {slug} = params;
    console.warn(slug)
    const data = Careers.find(item => item.postionId === slug);
    console.log(typeof(data.duties));
  return(
      <div>
        <section className="page-title bg-1">
          <div className="">
            <div className="row">
              <div className="col-md-12">
                <div className="block">
                  {/* <span className="text-white">Careers</span> */}
                  <h1 className="text-capitalize mt-5 text-lg">{data.title}</h1>
                  <p>{data.category}</p>
                  <ul className="list-inline breadcumb-nav" style={ulStyle}>
                    <li className="list-inline-item"><a href="/" className="text-white">Home</a></li>
                    <li className="list-inline-item"><span className="text-white">/</span></li>
                    <li className="list-inline-item"><a href="/careers" className="text-white-50">Careers</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section case-study">
            <div className="container">
                <div className="row">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="case-img">
                                <img style={styles.img} src="assets/images/about/about.jpg" alt="" className="img-fluid" />
                            </div>
                            <h3 className="mb-3">{data.title}</h3>
                            <p style={styles.p}><div dangerouslySetInnerHTML={{__html: data.description}} /></p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="case-content pl-4 mt-4 mt-lg-0">
                                <h6 class="mb-3">Requirements and qualifications</h6>
                                <div dangerouslySetInnerHTML={{__html: data.qualifications}} />
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="case-content pl-4 mt-4 mt-lg-0">
                                <h6 class="mb-3">Duties and responsibilities</h6>
                                <div dangerouslySetInnerHTML={{__html: data.duties}} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
      </div>
    );
  }